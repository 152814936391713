<template>
  <main>
    <div class="container header">
      <img src="@/assets/images/avatar.png" alt="" />
      <div class="header_r">
        <div class="r_top">
          <p class="title">Sunil Agarwal</p>

          <p class="r_content">
            The Chief Investment Strategist for the Asia-Pacific region at
            BlackRock Investment Research Institute.  
          </p>
          <p class="r_content">
            Sunil Agarwal, the Managing Director, a chartered Financial Analyst,
            and the Chief Investment Strategist for the Asia Pacific region at
            BlackRock Investment Research Institute, also serves as the
            portfolio manager for the Indian stock team.
          </p>
          <p class="r_content">
            He is based in India and Singapore. Sunil Agarwal is responsible for
            providing market insights and investment advice for the Indian
            market and Asia Pacific region.
          </p>
          <p class="r_content">
            He often shares BlackRock’s views on the Asia Pacific market through
            media and podcasts, represents BlackRock in numerous public debates
            on the stock market and economy, and has written many insightful
            reports.
          </p>
          <p class="r_content">
            The Indian stock team led by Sunil Agarwal has published insightful
            investment reports and research findings including “Investment
            Opportunities in India in 2024”, “India’s Wealthy Population to
            Reach 100 Million by 2027”, “India to Become the World’s Second
            Largest Economy by 2075”.
          </p>
          <p class="r_content">
            Sunil Agarwal has over 27 years of experience in the capital
            markets.   Before his current role, Sunil Agarwal spent six years as
            an analyst and portfolio manager at Fidelity International.
          </p>
          <p class="r_content">
            He holds a Bachelor’s degree in Business from the University of
            Mumbai and a Master’s degree in Business Administration with a
            concentration in finance from the Wharton school at the University
            of Pennsylvania.
          </p>
          <p class="r_content">
            He is a provisional member of the Institute of Chartered Accountants
            of India and is a Chartered Financial Analyst (CFA).
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.header {
  padding: 60px;
  display: flex;
  display: flex;
  justify-content: center;
  font-family: PingFangSC-Regular;

  img {
    width: 240px;
    height: 240px;
    object-fit: cover;
  }

  .header_r {
    width: 60%;
    color: #000;

    .r_top {
      margin-left: 40px;

      .title {
        font-weight: normal;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 2px;
        font-family: FortBook, Arial, sans-serif;
      }

      .title-name {
        font-size: 20px;
        font-weight: 700;
      }

      .p_1 {
        font-weight: bold;
        font-style: italic;
        margin-bottom: 30px;
      }

      .r_content {
        line-height: 26px;
        letter-spacing: 1px;
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        font-weight: normal;
        margin-top: 16px;
      }
    }
  }

  .content {
    width: 75%;
    margin: 40px auto 0;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    letter-spacing: 1px;
    font-family: PingFangSC-Regular;
  }
}

@media (max-width: 1200px) {
  .header {
    flex-direction: column;

    .header_r {
      width: 100%;

      .r_top {
        margin-left: 0;

        .p_1 {
          margin-top: -40px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
