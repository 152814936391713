<!-- Please remove this file from your project -->
<template>
  <div>
    <footer class="footer">
      <div class="first">
        <div class="row-padding">
          <h2 class="bold">Explore more</h2>
          <div class="footer_content">
            <div class="item" v-for="(cItem, idx) in footerList" :key="idx">
              <p class="bold">{{ cItem.title }}</p>
              <span class="line"></span>
              <span v-for="(i, ix) in cItem.content" :key="ix" :class="i.style">{{ i.cons }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="second">
        <div class="cont_s">
          <p class="t bold">BlackRock.</p>
          <p>As a global investment manager and fiduciary to our clients, our purpose at BlackRock is to help everyone
            experience financial well-being. Since 1999, we've been a leading provider of financial technology, and our
            clients turn to us for the solutions they need when planning for their most important goals.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "NuxtFooter",
  data() {
    return {
      footerList: [
        {
          title: 'About Us',
          content: [
            {
              cons: 'About BlackRock',
              style: 'bold'
            },
            {
              cons: 'Principles',
              style: ''
            },
            {
              cons: 'Leadership',
              style: ''
            },
            {
              cons: 'History',
              style: ''
            },
            {
              cons: 'Global Impact',
              style: ''
            },
            {
              cons: 'Contacts and Locations',
              style: ''
            },
            {
              cons: 'Doing Business with BlackRock',
              style: ''
            }
          ]
        },
        {
          title: 'Newsroom',
          content: [
            {
              cons: 'Overview',
              style: 'bold'
            },
            {
              cons: 'Media Contacts',
              style: ''
            }
          ]
        },
        {
          title: 'Insights',
          content: [
            {
              cons: 'INSIFHTS & VIEWS',
              style: 'bold'
            },
            {
              cons: 'BlackRock Investment Institute',
              style: ''
            },
            {
              cons: 'Investment Stewardship',
              style: ''
            },
            {
              cons: 'Our approach to sustainability',
              style: ''
            },
            {
              cons: 'Public Policy',
              style: ''
            },
            {
              cons: 'Long-term capitalism',
              style: ''
            },
            {
              cons: 'Investor Perspectives',
              style: ''
            }
          ]
        },
        {
          title: 'Investor Relations',
          content: [
            {
              cons: 'Overview',
              style: ''
            },
            {
              cons: 'Annual Reports & Proxy',
              style: ''
            },
            {
              cons: 'Information',
              style: ''
            },
            {
              cons: 'SEC Filings',
              style: ''
            },
            {
              cons: 'Stock Information',
              style: ''
            },
            {
              cons: 'Dividend History',
              style: ''
            },
            {
              cons: 'Event & Presentations',
              style: ''
            },
            {
              cons: 'Corporate Governance',
              style: ''
            }
          ]
        },
        {
          title: 'Corporate sustainability',
          content: [
            {
              cons: 'BLACKROCK SUSTAINABILITY',
              style: 'bold'
            },
            {
              cons: 'Corporate Sustainability',
              style: ''
            },
            {
              cons: 'Human capital',
              style: ''
            },
            {
              cons: 'Environmental Sustainability',
              style: ''
            },
            {
              cons: 'Ethics & Integrity',
              style: ''
            },
            {
              cons: 'Health & Safety',
              style: ''
            },
            {
              cons: 'Social Impact',
              style: ''
            }
          ]
        },
        {
          title: 'Careers',
          content: [
            {
              cons: 'CAREERS AT BLACKROCK',
              style: 'bold'
            },
            {
              cons: 'Search Jobs',
              style: ''
            },
            {
              cons: 'Life at BlackRock',
              style: ''
            },
            {
              cons: 'Rewards & Benefits',
              style: ''
            },
            {
              cons: 'Inclusion & Diversity',
              style: ''
            },
            {
              cons: 'Learning & Development',
              style: ''
            },
            {
              cons: 'Students',
              style: ''
            },
            {
              cons: 'Our Teams',
              style: ''
            },
            {
              cons: 'Supporting Veterans',
              style: ''
            },
            {
              cons: 'BlackRock Alumni Network',
              style: ''
            }
          ]
        }
      ]
    }
  }
};
</script>
<style lang="less" scoped>
.footer {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  box-sizing: border-box;

  .first {
    background: #161616;
    padding: 0 100px;
    width: 100%;
    box-sizing: border-box;
  }

  .second {
    background: #000;
    padding: 0 100px;
    width: 100%;
    padding: 88px 0;
    display: flex;
    justify-content: center;

    .cont_s {
      width: 32%;

      .t {
        font-family: FortBook, Arial, sans-serif;
        font-size: 48px;
      }
    }
  }

  .footer_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    .item {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      margin-right: 16px;

      p {
        font-family: FortBook, Arial, sans-serif;
        font-size: 18px;
      }

      span {
        margin-bottom: 6px;
        color: #eee;
        font-size: 14px;

        &.bold {
          font-weight: 800;
          font-family: FortBook, Arial, sans-serif;
          color: #fff;
        }
      }

      .line {
        width: 100%;
        height: 0.02rem;
        background-color: #aaa;
        margin-bottom: 28px;
      }
    }
  }
}

.row-padding {
  padding: 60px 0;
}

@media screen and (min-width: 700px) and (max-width: 1400px) {

  .footer {
    font-size: 14px;

    .first {
      padding: 0 40px;
    }

    .second {
      padding: 60px 0;
      .cont_s {
        width: 90%;
      }
    }

    .footer_content {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .row-padding {
    padding: 20px;
  }
}

@media (max-width: 700px) {

  .footer {
    font-size: 14px;

    .first {
      padding: 0 40px;
    }

    .second {
      padding: 60px 0;
      .cont_s {
        width: 90%;
      }
    }

    .footer_content {
      grid-template-columns: 1fr;
    }
  }

  .row-padding {
    padding: 20px;
  }
}
</style>
