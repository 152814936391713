<template>
  <div class="header">
    <nav class="nav">
      <router-link to="/" :class="$route.path == '/' ? 'active' : ''">BlackRock Investment
        Institute</router-link>
      <router-link to="/home" :class="$route.path == '/home' ? 'active' : ''">Mentor Introduction</router-link>
    </nav>
  </div>

</template>

<script>
export default {
  data() {
    return {
      route: ''
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  background-color: #fff;
  border-bottom: 2px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 5%;
  position: fixed;
  top: 0;
  max-height: 78px;
  z-index: 999;


  .nav {
    // width: 40%;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      /* 去除下划线 */
      color: inherit;
      /* 继承父元素的文字颜色，或者根据需要设置具体的颜色 */
      margin-right: 72px;
      font-size: 22px;
      font-weight: bold;

      &.active {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          border-radius: 50%;
          width: 8px;
          height: 8px;
          left: 50%;
          bottom: -16px;
          transform: translateX(-50%);
          background: #333;
        }
      }
    }
  }


}

@media (max-width: 750px) {
  .header .nav a {
    white-space: nowrap;
    /* 禁止文本换行 */
    overflow: hidden;
    /* 隐藏溢出的文本 */
    text-overflow: ellipsis;
    /* 显示省略号 */
    width: 130px;
    /* 定义容器宽度 */
    margin-right: 36px;
  }
}
</style>